import request from './request';
import * as paths from './paths';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

const axiosCoreBlob = axios.create({
  baseURL: process.env.REACT_APP_QUALITY_MODULE_URL,
  timeout: 120000,
  responseType: 'blob', //BLOB için bu axios u kullanıyyoruz
  headers: { 'Accept-Language': 'tr-TR' },

});

axiosCoreBlob.interceptors.request.use(function (config) {
  const token = secureLocalStorage.getItem('token');

  if (!config.url.includes('public')) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  return config;
});

class QualityModuleAPI {

  static qualityKpiList = async () => {
    return await request.get(paths.qualityKpiModule);
  };

  static qualityKpiSave = async (params) => {
    console.log(params)
    return await request.post(paths.qualityKpiModule + "/save" , params);
  };

  static qualityKpiUpdate = async (id,params) => {
    return await request.put(paths.qualityKpiModule + "/" +  id, params);
  };

  static qualityKpiExtendedSave = async (params) => {
    return await request.post(paths.qualityKpiModuleExtended + "/",  params);
  };
  

  static qualityKpiById = async (id) => {
    return await request.get(paths.qualityKpiModule + "/" + id);
  };

  static copyConditionById = async (id) => {
    return await request.get("/api/qualityKpiConditionModule/copyConditionById/" + id);
  };

  static deleteConditionById = async (id) => {
    return await request.delete("/api/qualityKpiConditionModule/" + id);
  };
  static search = async (params) => {
    return await request.post(paths.qualityKpiModule + "/search" ,params);
  };

  static weightDefinitionsSearch = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/search" ,params);
  };

  static weightDefinitionsSave = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/save" , params);
  };

  static weightDefinitionsDelete = async (params) => {
    const url = paths.qMWeightDefinitions + "/delete/" + params;
    console.log(url);
    return await request.post(url);
  };

  static qualityModuleGetActualsData = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getActuals');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetAirports = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getAirports');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetAirlines = async (params) => {
    const url=(paths.qmFlightController + '/getAirlines/'+ params);
    console.log(url)
    console.log(params)
    
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetCompanies = async (params) => {
    console.log(params)
    const url=(paths.qmFlightController + '/getCompanies');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };
  static qmGetFlightPairs = async (airportId, airlineId) => {
    const url=(paths.qmFlightController + '/getFlightPairs/' + airportId + '/' + airlineId);
    console.log(url)
    return await request.post(url);
  ///api/qmFlightController/search
  };

  static getAirportStats = async (airportId) => {
    const url=(paths.qmFlightController + '/getAirportStats/' + airportId);
    console.log(url)
    return await request.post(url);
  ///api/qmFlightController/search
  };
  static qmGetAirportStats = async (airportId) => {
    const url=(paths.qmFlightController + '/getAirportStatsDetailed/' + airportId);
    console.log(url)
    return await request.post(url);
  ///api/qmFlightController/search
  };

  


  static qualityModuleSaveActualsData = async (params) => {
    const url=(paths.qmActualController + '/save');
    return await request.post(url , params);
  ///api/qmFlightController/search
  };

  static qualityModuleUploadData = async (params, headers) => {
    const url=(paths.qmActualController + '/upload');
    return await request.post(url , params, headers);
  ///api/qmFlightController/search
  };

  static qualityModuleGetFiles = async (flightLinkId, kpiId) => {
    const url=(paths.qmActualController + '/getFiles?flightLinkId='+ flightLinkId + "&kpiId=" + kpiId);
    //console.log(url)
    return await request.get(url);
  ///api/qmFlightController/search
  };

  static qualityModuleDownloadData = async (id) => {
    try {
        // URL oluştur
        //const url = `http://localhost:39494/api/qmKPIActual/download/${id}`;
        const url=(paths.qmActualController + '/download/'+ id);

        // Axios ile GET isteği gönder
        const response = await axiosCoreBlob.post(url);

        // Başarıyla yanıt dönerse
        console.log('Download successful:', response);
        return response; // Blob'u döner
    } catch (error) {
        console.error('Error while downloading the file:', error);
        throw error; // Hata fırlat
    }
};

  
  static qualityModuleDownloadDataOld = async (id) => {
    //http://localhost:39494/api/qmKPIActual/download/1001
    const header = { responseType: 'blob', headers: {'Content-Type': 'application/json'}}
    const url=(paths.qmActualController + '/download/'+ id);
    //console.log(header)
    //return await request.post(url, {}, {}, {responseType: 'blob'});
  ///api/qmFlightController/search
  };
  

  static qualityModuleCompleteActualsData = async (flightLinkId) => {
    console.log(flightLinkId)
    const url=(paths.qmActualController + '/complete/'+ flightLinkId);
    return await request.post(url , flightLinkId);
  ///api/qmFlightController/search
  };

  static qualityModuleSetPosition = async (params) => {
    console.log(params.flightLinkId)
    const url=(paths.qmActualController + '/setPosition/'+ params.flightLinkId + "/" + params.position);
    return await request.post(url , params.flightLinkId, params.position);
  ///api/qmFlightController/search
  };

  static qualityModuleFlights = async (params) => {
    const url=(paths.qmFlightController + '/getFlights?airlineId='+params.airlineId+'&airportId='+params.airportId);
    return await request.post(url , params);
  ///api/qmFlightController/search
  };

  static qualityModuleFlightKPI = async (params) => {
    return await request.post(paths.qmFlightController + '/getFlightKpi?flightId='+params.flightId , params);
  ///api/qmFlightController/search
  };

  
  static qualityModuleFlightPairKPI = async (params) => {
    return await request.post(paths.qmFlightController + '/getFlightPairKpi?flightLinkId='+params.flightLinkId , params);
  ///api/qmFlightController/search
  };

}

export { QualityModuleAPI };
//update
