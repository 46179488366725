const clearDate = function (value) {
    if (value != null) {
        let date = new Date(String(value).substring(0, 10));
        let day = new Date(date).getDate();
        let month = new Date(date).getMonth() + 1; // Note the +1 to adjust for zero-based month
        let year = new Date(date).getFullYear();
        return day + "/" + month + "/" + year;
    }
    return "-";
}
const clearDateISO = function (value, timeset){
    if(!value) return "";
    var date = new Date(Date.parse(value));
    var m = date.getMonth()+1;
    var d = date.getDate();
    var dateISOstr = date.getFullYear()
                    +"-"+(m<10?"0":"")+m
                    +"-"+(d<10?"0":"")+d;

    switch(timeset){
        case 'atdaybegin':
            return dateISOstr += "T00:00:00.000Z";
            break;
        case 'atdayend':
            return dateISOstr += "T23:59:59.999Z";
            break;
        default:
            return value;
            break;
    }
}


const formatDate = function(dateString) {
    if (dateString != null) {
        const [month, day, year] = dateString.split('.');
        return `${day}/${month}/${year}`;
    }
    return "";
}

const formatDateAsddMMYYYY = function(dateString) {
    console.log(dateString);
    if (dateString != null) {
        const [day, month, year] = dateString.split('.');
        return `${day}/${month}/${year}`;
    }
    return "";
}

//local varsa override edecektir. İkili veya dörtlü kod olabilir. ["in" veya "en-IN"]
//If local is setted, override localization. Code in format as ["en" or "en-US"]
const clearFormat = function (value, companyId, local) {
    // 4050, 4052 Company Id olanlarda 
    // Total, Vat Amount, Cons. Fee 
    // Gelen Data: 1130.5 
    // İstenilen Data: 1.130,5
    let intlCode = null;
    let numberFormatObject = { minimumFractionDigits:2 };
    switch(companyId){
        case 4050:
        case 4052:
            // format x.xxx,xx
            intlCode = 'tr-TR';
            break;
        default:
            // format x,xxx.xx
            intlCode = 'en-US';
            break;
    }

    if(local) intlCode = local;
    let val = Math.round((value + Number.EPSILON) * 100) / 100;
    return Intl.NumberFormat(intlCode, numberFormatObject).format(val);
}

const localDateFormat = function(value, companyId){

    switch(companyId) {
        case 4050:
        case 4052:
            var utc = new Date(Date.parse(value));
            let day = utc.getUTCDate();
            let month = utc.getUTCMonth() + 1;
            let year = utc.getUTCFullYear();
            let hours = utc.getUTCHours();
            let minutes = utc.getUTCMinutes();
            let seconds = utc.getUTCSeconds();
            let msecs = utc.getMilliseconds();

            let isostr = year + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day + "T" + (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds + "." + msecs + "";
            return isostr;
            break;
        default:
            return value;
            break;
    }

}

export default { clearDate, clearFormat, formatDate, formatDateAsddMMYYYY, localDateFormat, clearDateISO }