import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accordionView: 'on',
    Filter: true,
    GroupPreview: false,
    DisplayingTheCreatedInvoice: false,
    InvoiceStatus: false,
    Step: '1',
    FormData: {},
    FormDate: null,
    FilterForm: {
        Company: {
            data: [],
            select: null
        },
        Airport: {
            select: []
        },
        Customers: {
            select: []
        },
        Representative: {
            select: []
        },
        FormType: {
            select: []
        },
        FlightType: {
            select: []
        },
        IncludedFormNumbers: {
            select: ""
        },
        ExcludedFormNumbers: {
            select: ""
        },
        Currency: {
            select: []
        },
        TariffType: {
            select: []
        },
        Registration: {
            select: []
        },
        ServiceCustomerGroups: {
            select: []
        },
        ServiceGroups: {
            select: []
        },
        Groups: {
            select: []
        },
        PrefixList: {
            data:[],
            select: null
        },
        LoadType: {
            select: []
        },
    },
    CreateInvoiceParams: {},
    Permissions: {},
    VatValues:[
        {label:0, id:0},
        {label:1, id:1},
        {label:8, id:8},
        {label:10, id:10},
        {label:18, id:18},
        {label:20, id:20}
    ]
};

export const InvoiceNavigationSlice = createSlice({
    name: 'InvoiceNavigation',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.Step = action.payload;
        },
        setAccordionViewOn: (state) => {
            state.accordionView = 'on';
        },
        setAccordionViewOff: (state) => {
            state.accordionView = 'off';
        },
        setStageName: (state, action) => {
            state.Filter = true;
            state.GroupPreview = false;
            state.DisplayingTheCreatedInvoice = false;
            state.InvoiceStatus = false;
        },
        switchAccordionExpandedState: (state, action) => {
            switch (action.payload?.index) {
                case 0:
                    state.Filter = action.payload?.value;
                    break;
                case 1:
                    state.GroupPreview = action.payload?.value;
                    break;
                case 2:
                    state.DisplayingTheCreatedInvoice = action.payload?.value;
                    break;
                case 3:
                    state.InvoiceStatus = action.payload?.value;
                    break;
                default:
            }
        },
        setFormData: (state, action) => {
            state.FormData = action.payload;
        },
        setFormDateRedux: (state, action) => {
            state.FormDate = action.payload
        },
        setInvoiceStatus: (state, action) => {
            state.FormData.Displaying.Status = action.payload
        },
        setAirport: (state, action) => {
            state.FilterForm.Airport.select = action.payload
        },
        setCustomers: (state, action) => {
            state.FilterForm.Customers.select = action.payload
        },
        setRepresentative: (state, action) => {
            state.FilterForm.Representative.select = action.payload
        },
        setFormType: (state, action) => {
            state.FilterForm.FormType.select = action.payload
        },
        setFlightType: (state, action) => {
            state.FilterForm.FlightType.select = action.payload
        },
        setIncludedFormNumbers: (state, action) => {
            state.FilterForm.IncludedFormNumbers.select = action.payload
        },
        setExcludedFormNumbers: (state, action) => {
            state.FilterForm.ExcludedFormNumbers.select = action.payload
        },
        setCurrency: (state, action) => {
            state.FilterForm.Currency.select = action.payload
        },
        setTariffType: (state, action) => {
            state.FilterForm.TariffType.select = action.payload
        },
        setPrefixListData:(state, action) => {
            state.FilterForm.PrefixList.data = action.payload
        },
        setPrefixSelect: (state, action) => {
            state.FilterForm.PrefixList.select = action.payload
            state.CreateInvoiceParams.invoicePrefix = action.payload?.code
        },
        setRegistration: (state, action) => {
            state.FilterForm.Registration.select = action.payload
        },
        setServiceCustomerGroups: (state, action) => {
            state.FilterForm.ServiceCustomerGroups.select = action.payload
        },
        setServiceGroups: (state, action) => {
            state.FilterForm.ServiceGroups.select = action.payload
        },
        setGroups: (state, action) => {
            state.FilterForm.Groups.select = action.payload
        },
        setCreateInvoiceParams: (state, action) => {
            state.CreateInvoiceParams = action.payload
        },
        setCompany: (state, action) => {
            state.FilterForm.Company.data = action.payload;
        },
        setCompanySelect: (state, action) => {
            state.FilterForm.Company.select = action.payload;
        },

        setPermissions: (state, action) => {
            state.Permissions = action.payload
        },
        setCalculatedServices: (state, action) => {
            state.CreateInvoiceParams.calculatedServices = action.payload
        },
        setInvoiceFinalize: (state, action) => {
            state.FormData.Displaying.SapDocumentNo = action.payload.SapDocumentNo
            state.FormData.Displaying.SapReversalDocumentNo = action.payload.SapReversalDocumentNo
            state.FormData.Displaying.Einvoiceuuid = action.payload.Einvoiceuuid
            state.FormData.Displaying.Einvoicestatus = action.payload.Einvoicestatus
            state.FormData.Displaying.Status = action.payload.Status
            state.FormData.Displaying.Irn = action.payload?.Irn
        },
        setFormBeginDate: (state, action) => {
            state.FilterForm.formBeginDate = action.payload;
        },
        setFormEndDate: (state, action) => {
            state.FilterForm.formEndDate = action.payload;
        },
        setFormDataGroupPreviewData: (state, action) => {
            state.FormData.GroupPreview.Data = action.payload;
        },
        setFormDataDisplaying: (state, action) => {
            state.FormData.Displaying = action.payload;
        },
        setLoadType: (state, action) => {
            state.FilterForm.LoadType.select = action.payload;
        },
        resetFilterForm: (state, action) => {
            state.FilterForm = initialState.FilterForm;
        }
    },
});

export const {
    setAccordionViewOn,
    setAccordionViewOff,
    setStageName,
    switchAccordionExpandedState,
    setStep,
    setFormData,
    setFormDataDisplaying,
    setFormDateRedux,
    setInvoiceStatus,
    setAirport,
    setCustomers,
    setRepresentative,
    setFormType,
    setFlightType,
    setCurrency,
    setTariffType,
    setPrefixListData,
    setPrefixSelect,
    setRegistration,
    setServiceCustomerGroups,
    setServiceGroups,
    setGroups,
    setCreateInvoiceParams,
    setPermissions,
    setCalculatedServices,
    setInvoiceFinalize,
    setCompany,
    setCompanySelect,
    setFormEndDate,
    setIncludedFormNumbers,
    setExcludedFormNumbers,
    setFormDataGroupPreviewData,
    setLoadType,
    resetFilterForm
} = InvoiceNavigationSlice.actions;
export default InvoiceNavigationSlice.reducer;